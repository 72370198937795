import { useEffect, useState } from 'react';
import useWindowSize from './useWindowSize';

export const useHeaderOffset = () => {
  const { isMobile } = useWindowSize();
  const [stickyOffset, setStickyOffset] = useState(0);

  useEffect(() => {
    const header = document?.getElementById('header');
    const offsetHeight = header?.offsetHeight;
    setStickyOffset(offsetHeight ?? 0);
  }, [isMobile]);

  return stickyOffset;
};

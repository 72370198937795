import { BREAKPOINTS } from 'constants/breakpoints';
import styled, { css } from 'styled-components';
import { color, spacer, variable, breakpointUp } from 'theme';

interface StyledTitleWrapperProps {
  active: boolean;
}

export const StyledTitleWrapper = styled.div<StyledTitleWrapperProps>`
  position: relative;
  transition: ${variable('transition')};
  cursor: pointer;
  padding-bottom: ${spacer(25)};

  color: ${color('whisper-400')};
  transition: all 0.2s;
  text-decoration: none;
  font-weight: bold;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      &:hover {
        color: ${color('primary-300')};
      }
    `
  )}

  ${({ active }) =>
    active &&
    css`
      color: ${color('primary-300')};
      border-bottom: ${variable('border-transparent')};
      border-bottom-width: 2px;
      border-bottom-color: ${color('primary-300')};
    `}
`;

import SliderCenteredArrowsWithMargin from 'components/SliderCenteredArrowsWithMargin/SliderCenteredArrowsWithMargin';
import { BREAKPOINTS } from 'constants/breakpoints';
import styled, { css } from 'styled-components';
import { color, variable, spacer, zIndex, breakpointUp } from 'theme';

interface StyledAnchorLinkSliderWrapperProps {
  stickyOffset: number;
}

export const StyledAnchorLinkSliderWrapper = styled.div<StyledAnchorLinkSliderWrapperProps>`
  background-color: ${color('white')};
  position: sticky;
  transition: ${variable('transition')};
  padding: ${spacer(100)} 0px;
  z-index: ${zIndex('header-underwrite')};
  top: ${({ stickyOffset }) => stickyOffset || 0}px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc((100vw - 100%) / -2);
    width: 100vw;
    height: 100%;
    background-color: ${color('white')};
  }
`;

interface StyledEmblaSliderProps {
  newDesign?: boolean;
}

export const StyledEmblaSlider = styled(SliderCenteredArrowsWithMargin)<StyledEmblaSliderProps>`
  ${({ newDesign }) =>
    newDesign &&
    css`
      ${breakpointUp(
        BREAKPOINTS.MD,
        css`
          .embla {
            .embla__container {
              gap: ${spacer(50)};
            }
          }
        `
      )}
    `}
`;

interface StyledAnchorLinkProps {
  active: boolean;
  isOneChar: boolean;
  newDesign?: boolean;
}

const activeLinkStyling = css<{ newDesign?: boolean }>`
  color: ${color('white')};
  background-color: ${color('primary-300')};

  ${({ newDesign }) =>
    newDesign &&
    css`
      font-weight: bold;
    `}
`;

export const StyledAnchorLink = styled.div<StyledAnchorLinkProps>`
  position: relative;
  padding: ${spacer(75)} ${spacer(150)};
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  flex-shrink: 0;
  border-radius: ${spacer(250)};
  color: ${color('black')};
  transition: ${variable('transition')};

  ${({ isOneChar }) =>
    isOneChar
      ? css`
          border-radius: 50%;
          width: ${spacer(200)};
          height: ${spacer(200)};
          display: flex;
          justify-content: center;
          align-items: center;
        `
      : css`
          ${breakpointUp(
            BREAKPOINTS.MD,
            css`
              padding: ${spacer(50)} ${spacer(175)};
            `
          )}
        `};

  ${({ newDesign }) =>
    newDesign &&
    css`
      color: ${color('black')};
      background-color: ${color('whisper-100')};
      font-weight: normal;

      ${breakpointUp(
        BREAKPOINTS.MD,
        css`
          padding: ${spacer(50)} ${spacer(100)};
        `
      )}
    `}

  ${({ active }) => active && activeLinkStyling}
  &.active {
    ${activeLinkStyling}
  }
`;

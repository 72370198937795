import Flex from 'components/Flex/Flex';
import Typography from 'components/Typography/Typography';
import { Spacer } from 'constants/styling';
import { EmblaCarouselType } from 'embla-carousel';
import { useHeaderOffset } from 'hooks/useHeaderOffset';
import { memo, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDebounceCallback } from 'usehooks-ts';
import { convertSpacerRemToPixels } from 'utils/styleUtil';
import { StyledAnchorLink, StyledAnchorLinkSliderWrapper, StyledEmblaSlider } from './AnchorLinkSlider.styled';

const DEBOUNCE_TIMEOUT = 100;
const DEFAULT_CONTENT_GAP = 100;

export type Anchorlink = {
  id: string;
  title: string;
};

type ContentSectionRef = {
  offsetTop: number;
  ref: HTMLElement | null;
};

interface AnchorLinkSliderProps {
  anchorLinks: Anchorlink[];
  content: JSX.Element[];
  contentGap?: Spacer;
  newDesign?: boolean;
}

const AnchorLinkSlider = ({
  anchorLinks,
  content,
  contentGap = DEFAULT_CONTENT_GAP,
  newDesign,
}: AnchorLinkSliderProps) => {
  const sliderWrapperRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<EmblaCarouselType>(null);
  const contentSectionRefs = useRef<ContentSectionRef[]>([]);
  const [activeAnchorLinkId, setActiveAnchorLinkId] = useState(anchorLinks[0]?.id || '');

  const stickyOffset = useHeaderOffset();

  const getAnchorElementId = (elementId: string) => elementId && `${elementId}-anchor-link`;

  const getOffsetHeight = () => {
    const header = document.getElementById('header');
    const headerOffset = header?.offsetHeight || 0;
    const sliderHeight = sliderWrapperRef.current?.offsetHeight || 0;
    return headerOffset + sliderHeight;
  };

  const handleEmblaSliderScroll = (contentSection: HTMLElement) => {
    const anchorLinkToSelect = sliderRef?.current
      ?.slideNodes()
      ?.filter((slide) => slide?.firstElementChild?.id?.includes(contentSection.id))?.[0];

    if (anchorLinkToSelect) {
      const index = sliderRef?.current?.slideNodes()?.indexOf(anchorLinkToSelect);
      index && sliderRef?.current?.scrollTo(index);
    }
  };

  const handleScrollContent = () => {
    const currentPosition = window.scrollY;

    contentSectionRefs.current.forEach(({ offsetTop, ref }) => {
      if (!ref) return;

      const contentSectionTop = offsetTop; // Get the top offset of the current contentSection.
      const contentSectionHeight = ref.offsetHeight; // Get the height of the current contentSection.
      const contentGapPx = convertSpacerRemToPixels(contentGap) ?? 0; // Transform carousel whitespace gap from spacer rem into pixels
      const offsetHeight = getOffsetHeight();

      if (
        currentPosition >= contentSectionTop - offsetHeight - contentGapPx &&
        currentPosition < contentSectionTop + contentSectionHeight - offsetHeight
      ) {
        setActiveAnchorLinkId(ref.id);
        handleEmblaSliderScroll(ref);
      } else if (currentPosition > contentSectionTop) {
        const lastContentSectionIndex = contentSectionRefs.current.length - 1;
        const lastContentSection = contentSectionRefs.current[lastContentSectionIndex];

        if (lastContentSection.ref) {
          setActiveAnchorLinkId(lastContentSection.ref.id);
          handleEmblaSliderScroll(lastContentSection.ref);
        }
      } else if (currentPosition === 0) {
        const firstContentSection = contentSectionRefs.current[0];

        if (firstContentSection.ref) {
          setActiveAnchorLinkId(firstContentSection.ref.id);
          handleEmblaSliderScroll(firstContentSection.ref);
        }
      }
    });
  };

  const handleScroll = useDebounceCallback(() => handleScrollContent(), DEBOUNCE_TIMEOUT);

  const handleAnchorClick = (destinationContentSection: ContentSectionRef, index: number) => {
    if (destinationContentSection) {
      const destinationId = destinationContentSection.ref?.id;
      destinationId && setActiveAnchorLinkId(destinationId);
      window.scrollTo({
        behavior: 'smooth',
        top: destinationContentSection.offsetTop - getOffsetHeight(),
      });
      sliderRef?.current?.scrollTo(index);
    }
  };

  useEffect(() => {
    // Calculate and store the offsetTop values of the content sections
    contentSectionRefs.current = contentSectionRefs.current.map(({ ref, ...rest }) => ({
      ...rest,
      offsetTop: ref?.offsetTop || 0,
      ref,
    }));

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Flex gap={contentGap} flexDirection="column">
      <StyledAnchorLinkSliderWrapper ref={sliderWrapperRef} stickyOffset={stickyOffset}>
        <StyledEmblaSlider sliderRef={sliderRef} newDesign={newDesign}>
          {anchorLinks.map((anchorLink, index) => {
            const active = anchorLink.id === activeAnchorLinkId;
            const destinationContentSection = contentSectionRefs.current[index];
            const anchorLinkId = getAnchorElementId(anchorLink.id);

            if (!anchorLink?.title) {
              return null;
            }

            return (
              <StyledAnchorLink
                key={anchorLinkId}
                id={anchorLinkId}
                active={active}
                isOneChar={anchorLink.title?.length === 1}
                onClick={() => handleAnchorClick(destinationContentSection, index)}
                newDesign={newDesign}
                hidden={!anchorLink.title}
              >
                <Typography color="">{anchorLink.title}</Typography>
              </StyledAnchorLink>
            );
          })}
        </StyledEmblaSlider>
      </StyledAnchorLinkSliderWrapper>

      <Flex flexDirection="column" gap={contentGap}>
        {content.map((contentSection, index) => {
          const id = anchorLinks[index].id;
          const key = `contentSection-${id}`;
          return (
            <div
              key={key}
              id={id}
              // @ts-ignore #FIXME: Ref type is not matching
              ref={(el: HTMLDivElement) =>
                el ? (contentSectionRefs.current[index] = { offsetTop: el.offsetTop, ref: el }) : undefined
              }
            >
              {contentSection}
            </div>
          );
        })}
      </Flex>
    </Flex>
  );
};
export default memo(AnchorLinkSlider, isEqual);

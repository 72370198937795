import { DEFAULT_SLIDES_OBJECT } from 'components/Product/ProductsSlider/ProductsSlider';
import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import Typography from 'components/Typography/Typography';
import { BREAKPOINTS } from 'constants/breakpoints';
import { EmblaCarouselType } from 'embla-carousel';
import { useEffect, useRef, useState } from 'react';
import { IGroupingProducts } from 'types/ContentfulTypes';
import ContentfulGroupingWrapper from '../../ContentfulGroupingWrapper/ContentfulGroupingWrapper';
import ContentfulProductCarousel from '../../ContentfulProductCarousel/ContentfulProductCarousel';
import { StyledTitleWrapper } from '../ContentfulGroupingProductCarousel.styled';

interface ContentfulGroupingProductCarouselSlider {
  items: IGroupingProducts[];
}

const ContentfulGroupingProductCarouselSlider = ({ items }: ContentfulGroupingProductCarouselSlider) => {
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);

  const productSliderRef = useRef<EmblaCarouselType>(null);
  const carouselSliderRef = useRef<EmblaCarouselType>(null);

  const carouselOptions = { dragFree: false, watchDrag: false };

  const carouselSlidesToShow: SlidesObject = {
    [BREAKPOINTS.XS]: 1,
    [BREAKPOINTS.SM]: 1,
    [BREAKPOINTS.MD]: 1,
    [BREAKPOINTS.LG]: 1,
    [BREAKPOINTS.XL]: 1,
    [BREAKPOINTS.XXL]: 1,
  };

  const handleTitleSliderClick = (index: number) => {
    carouselSliderRef?.current?.scrollTo(index);
    setActiveGroupIndex(index);
  };

  // Scroll to the first product when grouping carousel is changed
  useEffect(() => {
    if (carouselSliderRef?.current && productSliderRef?.current) {
      const scrollToFirstElement = () => {
        productSliderRef.current?.scrollTo(0);
      };

      carouselSliderRef.current?.on('select', scrollToFirstElement);

      return () => {
        carouselSliderRef.current?.off('select', scrollToFirstElement);
      };
    }
  }, [carouselSliderRef?.current, productSliderRef?.current]);

  if (!items?.length) {
    return null;
  }

  return (
    <ContentfulGroupingWrapper>
      <SliderEmbla itemGap={200}>
        {items.map((item, index) => {
          const active = activeGroupIndex === index;

          return (
            <StyledTitleWrapper
              key={`${item?.sys?.id}-title`}
              active={active}
              onClick={() => handleTitleSliderClick(index)}
            >
              <Typography color="">{item.fields.title}</Typography>
            </StyledTitleWrapper>
          );
        })}
      </SliderEmbla>

      <SliderEmbla
        ref={carouselSliderRef}
        onSelectCallback={(index) => setActiveGroupIndex(index)}
        slidesToShow={carouselSlidesToShow}
        options={carouselOptions}
      >
        {items.map((item) => (
          <ContentfulProductCarousel
            key={item.sys.id}
            content={item}
            nested
            slidesObject={DEFAULT_SLIDES_OBJECT}
            productSliderRef={productSliderRef}
          />
        ))}
      </SliderEmbla>
    </ContentfulGroupingWrapper>
  );
};

export default ContentfulGroupingProductCarouselSlider;

import { Image, Link } from 'ui';
import { cn } from 'utils/cn';
import usePlatform from '../../hooks/usePlatform';

interface AppButtonProps {
  className?: string;
}

const AppButton = ({ className }: AppButtonProps) => {
  const { isKrefel } = usePlatform();

  if (!isKrefel) {
    return null;
  }

  return (
    <div className={cn('flex flex-row gap-2', className)}>
      <Link newTab href="https://link.krfl.be/6Ewj">
        <Image alt="App store image" className="h-10 w-[120px]" src="/images/badge_app_store.svg" />
      </Link>
      <Link newTab href="https://link.krfl.be/6Ewi">
        <Image alt="Google play image" className="h-10 w-[120px]" src="/images/badge_google_play.svg" />
      </Link>
    </div>
  );
};

export default AppButton;

import useWindowSize from 'hooks/useWindowSize';
import { memo, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { IGroupingProductCarousel } from 'types/ContentfulTypes';
import { cn } from 'utils/cn';
import { removeWhiteSpaces } from '../../../../utils/textUtil';
import ContentfulGroupingHeading from '../ContentfulGroupingHeading/ContentfulGroupingHeading';
import ContentfulGroupingWrapper from '../ContentfulGroupingWrapper/ContentfulGroupingWrapper';
import ContentfulGroupingProductCarouselSlider from './ContentfulGroupingProductCarouselSlider/ContentfulGroupingProductCarouselSlider';
import ContentfulGroupingProductCarouselTile from './ContentfulGroupingProductCarouselTile/ContentfulGroupingProductCarouselTile';

interface ContentfulGroupingProductCarouselProps {
  content: IGroupingProductCarousel;
}

const ContentfulGroupingProductCarousel = ({ content }: ContentfulGroupingProductCarouselProps) => {
  const [stickyOffset, setStickyOffset] = useState(0);

  const items = content?.fields?.items;
  const title = content?.fields?.title;

  const { isMobile } = useWindowSize();

  const filteredItems = items?.filter((item) => {
    const itemTitle = item?.fields?.title;
    return itemTitle ? removeWhiteSpaces(itemTitle) : null;
  });

  useEffect(() => {
    const scrollHeight = document?.getElementById('header')?.scrollHeight;
    scrollHeight && setStickyOffset(scrollHeight);
  }, [isMobile]);

  if (!content?.fields || !filteredItems?.length) {
    return null;
  }

  const { type } = content.fields;
  const isTile = type?.startsWith('Tile');

  return (
    <ContentfulGroupingWrapper className={cn(isTile ? 'gap-2' : '')}>
      {title && <ContentfulGroupingHeading>{title}</ContentfulGroupingHeading>}

      {isTile && type ? (
        <ContentfulGroupingProductCarouselTile stickyOffset={stickyOffset} items={filteredItems} type={type} />
      ) : (
        <ContentfulGroupingProductCarouselSlider items={filteredItems} />
      )}
    </ContentfulGroupingWrapper>
  );
};

export default memo(ContentfulGroupingProductCarousel, isEqual);

import AnchorLinkSlider from 'components/AnchorLinkSlider/AnchorLinkSlider';
import { CF_PRODUCT_TILE_PAGE_SIZE } from 'constants/cms';
import { ComponentProps, memo } from 'react';
import isEqual from 'react-fast-compare';
import { IGroupingProducts } from 'types/ContentfulTypes';
import ContentfulProductTiles from '../../ContentfulProductTiles/ContentfulProductTiles';

const CAROUSEL_WHITESPACE_GAP = 300;

interface ContentfulGroupingProductCarouselTile {
  items: IGroupingProducts[];
  stickyOffset: number;
  type: string;
}

type AnchorLink = ComponentProps<typeof AnchorLinkSlider>['anchorLinks']['0'];

const ContentfulGroupingProductCarouselTile = ({ items }: ContentfulGroupingProductCarouselTile) => {
  if (!items?.length) {
    return null;
  }

  const anchorLinks: AnchorLink[] = items.reduce((acc: AnchorLink[], item) => {
    if (item?.fields?.title) {
      acc.push({ id: item.sys.id, title: item.fields.title });
    }
    return acc;
  }, []);

  return (
    <AnchorLinkSlider
      anchorLinks={anchorLinks}
      content={items.map((item) => (
        <ContentfulProductTiles
          key={`ContentfulProductTiles-${item.sys.id}`}
          content={item}
          pageSize={CF_PRODUCT_TILE_PAGE_SIZE}
        />
      ))}
      contentGap={CAROUSEL_WHITESPACE_GAP}
    />
  );
};

export default memo(ContentfulGroupingProductCarouselTile, isEqual);
